import { colors, fonts, containerStyles, breakpoints } from '../styles';
import React, { Fragment } from 'react';
import styled from 'styled-components';

interface ButtonProps {
    className?: string;
    disabled?: boolean;
    onClick?:
        | React.MouseEventHandler<HTMLDivElement>
        | React.MouseEventHandler<HTMLButtonElement>;
    shadow?: boolean;
    backgroundColor?: string;
    buttonType?: string;
    children?: React.ReactNode;
    borderColor?: any;
    textColor?: any;
    backgroundHoverColor?: string;
}

const PrimaryButton = styled.button<ButtonProps>`
    ${fonts.button}
    padding: 12px 16px;
    border-radius: 3px;
    background: none;
    border: none;
    background-color: ${(props) =>
        props.backgroundColor || colors.primaryButton};
    &:hover {
        background-color: ${(props) =>
            props.backgroundHoverColor || colors.primaryButtonHover};
    }
    color: ${colors.primaryButtonText};
    font-weight: bold;
    cursor: ${(props) => (props.disabled ? 'inherit' : 'pointer')};
    opacity: ${(props) => (props.disabled ? 0.3 : 1)};
    ${breakpoints.sm} {
        width: ${containerStyles.nextStepHeight}px;
    }
    ${breakpoints.md} {
        width: ${containerStyles.nextStepHeight}px;
    }
    ${breakpoints.lg} {
        width: ${containerStyles.nextStepHeight}px;
    }
    ${breakpoints.largerDesktop} {
        width: ${containerStyles.nextStepHeight}px;
    }
    ${breakpoints.largeDesktop} {
        width: ${containerStyles.nextStepHeight}px;
    }
    ${breakpoints.onlyMobile} {
        font-size: 16px;
    }
`;

const SecondaryButton = styled.button<ButtonProps>`
    ${fonts.button}
    padding: 12px 16px;
    border-radius: 3px;
    background: none;
    border: 1px solid
        ${(props) => props.borderColor || colors.libertyAtmosphericWhite};
    color: ${(props) => props.textColor || colors.white};
    font-weight: bold;
    cursor: ${(props) => (props.disabled ? 'inherit' : 'pointer')};
    opacity: ${(props) => (props.disabled ? 0.3 : 1)};
    &:hover {
        background: rgba(255, 255, 255, 0.1);
    }
    ${breakpoints.sm} {
        width: ${containerStyles.nextStepHeight}px;
    }
    ${breakpoints.md} {
        width: ${containerStyles.nextStepHeight}px;
    }
    ${breakpoints.lg} {
        width: ${containerStyles.nextStepHeight}px;
    }
    ${breakpoints.largerDesktop} {
        width: ${containerStyles.nextStepHeight}px;
    }
    ${breakpoints.largeDesktop} {
        width: ${containerStyles.nextStepHeight}px;
    }
    ${breakpoints.onlyMobile} {
        font-size: 16px;
    }
`;

const Button: React.FC<ButtonProps> = ({
    buttonType,
    children,
    className,
    disabled,
    shadow = true,
    backgroundColor,
    textColor,
    borderColor,
    onClick = () => {
        return;
    },
    backgroundHoverColor,
}) => (
    <Fragment>
        {buttonType === 'secondary' ? (
            <SecondaryButton
                onClick={(e: any) => onClick(e)}
                className={className}
                disabled={disabled}
                shadow={shadow}
                textColor={textColor}
                borderColor={borderColor}
                backgroundColor={backgroundColor}
            >
                {children}
            </SecondaryButton>
        ) : (
            <PrimaryButton
                onClick={(e: any) => onClick(e)}
                className={className}
                disabled={disabled}
                shadow={shadow}
                backgroundColor={backgroundColor}
                backgroundHoverColor={backgroundHoverColor}
            >
                {children}
            </PrimaryButton>
        )}
    </Fragment>
);

export default Button;
