import { AnswerLevel } from '../model/models';
import AnswerButton from './AnswerButton';
import React, { useState } from 'react';
import styled from 'styled-components';

const backgroundType = 'shortcut';

interface AnswersProps {
    onAnswer: (answerLevel: AnswerLevel) => void;
    loading?: boolean;
    previousAnswer?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
    survey_id: any;
    levels_state: any;
    showLevels?: boolean;
}
const AnswersWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 250px;
`;

const SizedAnswer = styled(AnswerButton)`
    height: 50px;
`;

const Answers: React.FC<AnswersProps> = ({
    onAnswer,
    survey_id,
    levels_state,
    loading,
    previousAnswer,
    showLevels = true,
}) => {
    const [answerLoading, setAnswerLoading] = useState(-1);

    return (
        <AnswersWrapper>
            {showLevels &&
                levels_state &&
                levels_state.levels_data.map((level: any) => (
                    <SizedAnswer
                        key={level.id}
                        index={level.id}
                        levels_state={levels_state}
                        level={level.level}
                        color={level.color}
                        shortcut={level.level.toString()}
                        backgroundType={backgroundType}
                        loading={
                            previousAnswer === level.level ||
                            (loading && answerLoading === level.level)
                        }
                        onClick={() => {
                            setAnswerLoading(level.level);
                            onAnswer(level.level);
                        }}
                    />
                ))}
        </AnswersWrapper>
    );
};

export default Answers;
